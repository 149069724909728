import React from "react";
import { IoMdClose } from "react-icons/io";

const ChatHeader = ({ handleCloseChat }) => (
  <div className="h-[70px] bg-blue-500 rounded-t-lg flex items-center justify-between p-2">
    <div className="flex items-center">
      <img
        src="https://via.placeholder.com/40"
        alt="Chatbot Avatar"
        className="rounded-full"
      />
      <h2 className="text-white ml-2 font-bold">Chatbot</h2>
    </div>
    <button onClick={handleCloseChat} className="text-white">
      <IoMdClose size={24} />
    </button>
  </div>
);

export default ChatHeader;
