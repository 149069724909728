import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearProperties,
  addProperties,
} from "../../store/slices/propertySlice";
import { getApi } from "../Api/api";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Loader from "./Loader";
import {
  mapLotAreaToProperties,
  mapZolaDataToProperties,
  isPropertyInStore,
} from "../utils/homeUtils";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

const FormTable = () => {
  const properties = useSelector((state) => state.properties?.properties);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 10;
  const [propertyStatuses, setPropertyStatuses] = useState({});
  const [isStatusFetched, setIsStatusFetched] = useState(false);
  const [loading, setLoading] = useState(true); // Start with loading true
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [zolaData, setZolaData] = useState([]);
  const [lotArea, setLotArea] = useState([]);
  const [filters, setFilters] = useState({
    zip_code: "",
    max_apartments_allowed: "",
    residential_far: "",
    residential_fa: "",
    price: "",
    lot_area: "",
    zoning_district: "",
    listing_updates: "",
  });
  const [zoningDistricts, setZoningDistricts] = useState([]);

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const itemsPerPage = 12;
  const cardSectionRef = useRef(null);
  const fetchedProperties = useRef(new Set());

  console.log("properties", properties.length);

  const handleLogout = () => {
    Cookies.remove("authToken");
    navigate("/admin-login");
  };

  const handleFormFill = () => {
    navigate("/operator");
  };

  const handleGoBack = () => {
    navigate("/admin");
  };

  const filterData = () => {
    dispatch(clearProperties());
  };

  const fetchStatuses = async () => {
    try {
      const response = await getApi("check/status");
      if (response.status === "success") {
        const statusData = response.data.reduce((acc, item) => {
          acc[item.address] = item.status;
          return acc;
        }, {});
        setPropertyStatuses(statusData);
        setIsStatusFetched(true);
      } else {
        console.error("Failed to fetch statuses");
      }
    } catch (error) {
      console.error("Error fetching statuses:", error);
    }
  };

  const lastPropertyElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    const getZoningDistricts = async () => {
      try {
        const response = await getApi("address/districts");
        setZoningDistricts(response);
      } catch (error) {
        console.log("error", error);
      }
    };
    getZoningDistricts();
  }, []);

  useEffect(() => {
    if (properties.length <= currentPage * propertiesPerPage) {
      setHasMore(false);
    }
  }, [properties, currentPage, propertiesPerPage]);

  useEffect(() => {
    const getAllData = async () => {
      const response = await getApi("address/get-address");
      if (response?.data) {
        setData(response.data);
      }
    };
    getAllData();
  }, []);

  useEffect(() => {
    const getZolaData = async () => {
      try {
        const response = await getApi("operator/operator-get");
        if (response?.data) {
          setZolaData(response?.data);
        } else {
          setZolaData("N/A");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getZolaData();
  }, []);

  useEffect(() => {
    const getLotArea = async () => {
      try {
        const response = await getApi("address/lotarea");
        if (response?.data) {
          setLotArea(response?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getLotArea();
  }, []);

  useEffect(() => {
    if (data.length > 0 && lotArea.length > 0 && zolaData.length > 0) {
      let mappedProperties = mapLotAreaToProperties(data, lotArea);
      mappedProperties = mapZolaDataToProperties(mappedProperties, zolaData);
      setFilteredData(mappedProperties);
    }
  }, [data, lotArea, zolaData]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (data.length > 0) {
        console.log("function called");
        try {
          const response = await getApi("address/data");

          response.forEach((item) => {
            if (item?.zolaData) {
              const propertyData = {
                ...item.zolaData,
                formUrl: item.formLink,
                scrapLink: item.scrapLink,
              };

              if (!isPropertyInStore(propertyData, properties)) {
                dispatch(addProperties([propertyData]));
                fetchedProperties.current.add(propertyData.name);
              }
            }
          });
        } catch (error) {
          console.error("Error fetching property data:", error);
        }
      } else {
        console.log("No data available to fetch");
      }
    };

    fetchPropertyDetails();
  }, [data, dispatch, properties]);

  useEffect(() => {
    if (
      data.length > 0 &&
      lotArea.length > 0 &&
      zolaData.length > 0 &&
      properties.length > 0
    ) {
      setLoading(false);
    }
  }, [data, lotArea, zolaData, properties]);

  const filteredDistricts =
    query === ""
      ? zoningDistricts
      : zoningDistricts?.filter((district) => {
          return district?.toLowerCase()?.includes(query?.toLowerCase());
        });

  const completedCount = Object.values(propertyStatuses).filter(
    (status) => status === "Completed"
  ).length;

  // Combine filtering and sorting logic
  const filteredAndSortedProperties = properties
    .filter((property) =>
      selected ? property.zoningDistricts.includes(selected) : true
    )
    .sort((a, b) => {
      const statusA = propertyStatuses[a.address] || "Pending";
      const statusB = propertyStatuses[b.address] || "Pending";
      if (statusA === "Completed" && statusB !== "Completed") return -1;
      if (statusA !== "Completed" && statusB === "Completed") return 1;
      return 0;
    });

  const currentFilteredAndSortedProperties = filteredAndSortedProperties?.slice(
    0,
    currentPage * propertiesPerPage
  );

  return (
    <>
      <nav className="w-full max-w-screen px-4 py-2 mx-auto bg-white shadow-md rounded-md lg:px-8 lg:py-3 flex justify-between items-center">
        <span className="text-3xl font-sans font-bold">Admin Panel</span>

        <div>
          <button
            className="text-white bg-gray-700 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            onClick={handleGoBack}
          >
            Go Back to Admin Panel
          </button>
          <button
            className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={handleFormFill}
          >
            Form Fill
          </button>

          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </nav>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="overflow-x-auto p-4 lg:p-8">
            <div className="flex flex-col sm:flex-row w-full justify-end gap-5">
              <div className="text-blue-500">
                Total records = {properties.length}
              </div>
              <div className="text-green-500">Completed = {completedCount}</div>
              <div className="text-red-500">
                Pending = {properties.length - completedCount}
              </div>

              <Combobox
                value={selected}
                onChange={(value) => setSelected(value)}
                onClose={() => setQuery("")}
              >
                <div className="relative">
                  <ComboboxInput
                    className={clsx(
                      "w-full rounded-lg border-none bg-blue-500 py-2 pr-8 pl-3 mb-2 text-sm text-white placeholder-white",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-blue-300"
                    )}
                    displayValue={(district) => district}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Zoning District Filters"
                  />
                  <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                    <ChevronDownIcon className="size-6 fill-blue-300 group-data-[hover]:fill-blue-500" />
                  </ComboboxButton>
                </div>

                <ComboboxOptions
                  anchor="bottom"
                  transition
                  className={clsx(
                    "w-[var(--input-width)] rounded-xl border border-black bg-gray-200 p-1 [--anchor-gap:var(--spacing-1)] empty:invisible",
                    "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                  )}
                >
                  {filteredDistricts.map((district) => (
                    <ComboboxOption
                      key={district}
                      value={district}
                      className="group flex cursor-default items-center hover:curso gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white"
                    >
                      <CheckIcon className="invisible size-4 fill-blue-300 group-data-[selected]:visible" />
                      <div className="text-sm/6 text-black">{district}</div>
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </Combobox>
            </div>
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-blue-500 text-gray-600 uppercase text-md leading-normal">
                  <th className="py-3 px-6 text-left text-white">Address</th>
                  <th className="py-3 px-6 text-left text-white">
                    Zoning District
                  </th>
                  <th className="py-3 px-6 text-left text-white">
                    Address Link
                  </th>
                  <th className="py-3 px-6 text-left text-white">Form Link</th>
                  <th
                    onClick={fetchStatuses}
                    className="py-3 px-6 text-left text-white cursor-pointer"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-800 text-sm font-medium">
                {currentFilteredAndSortedProperties &&
                currentFilteredAndSortedProperties.length > 0 ? (
                  currentFilteredAndSortedProperties.map((property, index) => (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100"
                      ref={
                        index === currentFilteredAndSortedProperties.length - 1
                          ? lastPropertyElementRef
                          : null
                      }
                    >
                      <td className="py-3 px-6">{property?.address}</td>
                      <td className="py-3 px-6">
                        {property?.zoningDistricts?.join(", ") ||
                          "Not Available"}
                      </td>
                      <td className="py-3 px-6">
                        <a
                          href={property?.scrapLink}
                          className="text-blue-500 hover:underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Map
                        </a>
                      </td>
                      <td className="py-3 px-6">
                        {property?.formUrl ? (
                          <a
                            href={property?.formUrl}
                            className="text-blue-500 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Fill Form
                          </a>
                        ) : (
                          "Not Available"
                        )}
                      </td>
                      <td className="py-3 px-6">
                        {propertyStatuses[property.address] || "pending"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="py-3 px-6 text-center">
                      No properties available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default FormTable;