import React from "react";
import { MapPin, Bed, Bath, Square } from "lucide-react";

const ImageSection = ({ imgUrls }) => (
  <div className="flex h-64">
    <div className="w-full border-r border-gray-200">
      <img
        src={imgUrls[0]}
        alt="Main property view"
        className="w-full h-full object-cover"
      />
    </div>
  </div>
);

const HeaderSection = ({ name, zipcode, price }) => (
  <div className="flex justify-between items-center mb-4">
    <div>
      <h2 className="text-base sm:text-lg font-bold text-gray-800 my-2">
        {name} {zipcode}
      </h2>
      <span className="text-base sm:text-lg font-bold text-blue-500">
        {price}
      </span>
    </div>
  </div>
);

const DetailsSection = ({ location, beds, baths, sqft }) => (
  <div className="flex items-center max-2xl:text-sm justify-between flex-wrap gap-y-2 sm:gap-y-0">
    <div className="w-1/2 sm:w-auto flex items-center">
      <p className="flex items-center text-gray-600 text-sm sm:text-base">
        <MapPin className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" />
        {location}
      </p>
    </div>
    <div className="w-1/2 sm:w-auto flex items-center gap-1">
      <Bed className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" />
      <span className="text-sm sm:text-base">{beds}</span>
    </div>
    <div className="w-1/2 sm:w-auto flex items-center gap-1">
      <Bath className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" />
      <span className="text-sm sm:text-base">{baths}</span>
    </div>
    <div className="w-1/2 sm:w-auto flex items-center">
      <Square className="w-4 h-4 sm:w-5 sm:h-5 text-gray-500" />
      <span className="text-sm sm:text-base">{sqft}</span>
    </div>
  </div>
);

const AdditionalInfoSection = ({
  lotArea,
  zoningDistricts,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
}) => {
  const formattedZoningDistricts = Array.isArray(zoningDistricts)
    ? zoningDistricts.join(", ")
    : zoningDistricts;

  return (
    <div>
      <div className="flex justify-between mt-1">
        <div className="flex items-center space-x-2">
          <p className="text-gray-600 text-sm sm:text-md mt-2">Lot Area</p>
          <p className="font-bold text-sm sm:text-md mt-2">{lotArea}</p>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-gray-600 text-sm sm:text-md">Zoning District:</span>
          <span className="font-bold text-sm sm:text-md">{formattedZoningDistricts}</span>
        </div>
      </div>
      <div className="flex flex-row justify-between mt-2">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <span className="text-gray-600 text-sm sm:text-md">Residential FAR:</span>
            <span className="font-bold text-sm sm:text-md">{FARs?.join(", ")}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-600 text-sm sm:text-md">Residential FA:</span>
            <span className="font-bold text-sm sm:text-md">{FAs?.join(", ")}</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-gray-600 text-sm sm:text-md">Max Apartments Allowed:</span>
            <span className="font-bold text-sm sm:text-md">{maxApartments?.join(", ")}</span>
          </div>
          <div className="hidden">
            <span className="text-gray-600 text-sm sm:text-md">Listing Updated:</span>
            <span className="font-bold text-sm sm:text-md">{listingUpdated}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ButtonSection = ({ handleClick }) => (
  <div className="flex items-center justify-center mt-4">
    <button
      onClick={handleClick}
      className="bg-blue-500 w-full text-white rounded-md font-semibold hover:bg-blue-700 transition-colors px-3 py-2 text-sm md:text-base"
    >
      View Property
    </button>
  </div>
);

const Card = ({
  title,
  price,
  location,
  beds,
  baths,
  sqft,
  imgUrls,
  propertyUrls,
  zipcode,
  lotArea,
  FARs,
  FAs,
  maxApartments,
  listingUpdated,
  zoningDistricts,
}) => {
  const handleClick = () => {
    if (propertyUrls && propertyUrls.length > 0) {
      window.open(propertyUrls[0], "_blank");
    }
  };
  const name = title.replace(/\s\d{5}$/, "");
  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full flex flex-col">
      <ImageSection imgUrls={imgUrls} />
      <div className="p-4 md:p-6 flex flex-col">
        <HeaderSection name={name} zipcode={zipcode} price={price} />
        <DetailsSection
          location={location}
          beds={beds}
          baths={baths}
          sqft={sqft}
        />
        <AdditionalInfoSection
          lotArea={lotArea}
          zoningDistricts={zoningDistricts}
          FARs={FARs}
          FAs={FAs}
          maxApartments={maxApartments}
          listingUpdated={listingUpdated}
        />
        <ButtonSection handleClick={handleClick} />
      </div>
    </div>
  );
};

export default Card;