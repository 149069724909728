import React, { useEffect, useState, useRef } from "react";
import Card from "../../components/Card";
import FiltersModal from "../../components/FiltersModal";
import { getApi, postApi } from "../../Api/api";
import { useDispatch, useSelector } from "react-redux";
import { addProperties } from "../../../store/slices/propertySlice";
import {
  mapLotAreaToProperties,
  mapZolaDataToProperties,
  mergeData,
  applyFilters,
  resetFilters,
  chunkArray,
  isPropertyInStore,
} from "../../utils/homeUtils";
import Loader from "../../components/Loader";

const Home = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const properties = useSelector((state) => state.properties?.properties);
  const [zolaData, setZolaData] = useState([]);
  const [lotArea, setLotArea] = useState([]);
  const [filters, setFilters] = useState({
    zip_code: "",
    max_apartments_allowed: "",
    residential_far: "",
    residential_fa: "",
    price: "",
    lot_area: "",
    zoning_district: "",
    listing_updates: "",
  });
  const itemsPerPage = 12;
  const dispatch = useDispatch();
  const cardSectionRef = useRef(null);
  const fetchedProperties = useRef(new Set());

  useEffect(() => {
    const getAllData = async () => {
      const response = await getApi("address/get-address");
      if (response?.data) {
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
      }
    };
    getAllData();
  }, []);

  useEffect(() => {
    const getZolaData = async () => {
      try {
        const response = await getApi("operator/operator-get");
        if (response?.data) {
          setZolaData(response?.data);
        }else {
          setZolaData("N/A");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getZolaData();
  }, []);

  useEffect(() => {
    const getLotArea = async () => {
      try {
        const response = await getApi("address/lotarea");
        if (response?.data) {
          setLotArea(response?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getLotArea();
  }, []);

  useEffect(() => {
    if (data.length > 0 && lotArea.length > 0 && zolaData.length > 0) {
      let mappedProperties = mapLotAreaToProperties(data, lotArea);
      mappedProperties = mapZolaDataToProperties(mappedProperties, zolaData);
      setFilteredData(mappedProperties);
    }
  }, [data, lotArea, zolaData]);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      if (data.length > 0) {
        console.log("function called");
        try {
          const response = await getApi("address/data");

          response.forEach((item) => {
            if (item?.zolaData) {
              const propertyData = {
                ...item.zolaData,
                formUrl: item.formLink,
                scrapLink: item.scrapLink,
              };

              if (!isPropertyInStore(propertyData, properties)) {
                dispatch(addProperties([propertyData]));
                fetchedProperties.current.add(propertyData.name);
              }
            }
          });
        } catch (error) {
          console.error("Error fetching property data:", error);
        }
      } else {
        console.log("No data available to fetch");
      }
    };

    fetchPropertyDetails();
  }, [data, dispatch, properties]);

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  const currentData = filteredData.slice(firstItemIndex, lastItemIndex);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      scrollToTop();
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      scrollToTop();
    }
  };

  return (
    <div className="flex p-8">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex-1 mr-8" ref={cardSectionRef}>
          <div className="flex flex-row sm:flex-row justify-between items-center mb-4">
            <h1 className="text-2xl font-bold mb-4 sm:mb-0">Properties</h1>
            <div className="flex flex-col sm:flex-row">
              <button
                onClick={() => setModalOpen(!isModalOpen)}
                className="bg-blue-500 text-white p-2 sm:p-3 px-4 sm:px-6 rounded-md font-bold mb-2 sm:mb-0 sm:mr-2"
              >
                Filters
              </button>
              <button
                onClick={() =>
                  resetFilters(
                    setFilters,
                    setLoading,
                    setData,
                    setFilteredData,
                    setCurrentPage
                  )
                }
                className="bg-gray-500 text-white p-2 sm:p-3 px-4 sm:px-6 rounded-md font-bold"
              >
                Reset Filters
              </button>
            </div>
          </div>

          {currentData.length > 0 ? (
            <div className="grid grid-cols-3 max-sm:grid-cols-1 max-xl:grid-cols-2 gap-12 max-lg:grid-cols-1">
              {currentData.map((property, index) => (
                <Card
                  key={index}
                  title={property.name}
                  price={property.price}
                  location={property.address.streetAddress}
                  beds={property.beds}
                  baths={property.baths}
                  sqft={property.sqft}
                  imgUrls={property.imgUrls}
                  propertyUrls={property.propertyUrls}
                  zipcode={property.address.postalCode}
                  lotArea={property.lotArea}
                  FARs={property.FARs } 
                  FAs={property.FAs}
                  maxApartments={property.maxApartments}
                  zoningDistricts={property.zoningDistricts}
                  listingUpdated={property?.listingUpdated}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <h2 className="text-3xl font-bold text-gray-700 mb-4">
                Sorry, nothing found
              </h2>
              <p className="text-gray-500">
                Try adjusting your filters or search criteria.
              </p>
            </div>
          )}

          <div className="flex justify-center mt-8">
            {currentPage > 1 && (
              <button
                className="px-4 py-2 mx-2 bg-gray-300 rounded"
                onClick={handlePrevPage}
              >
                Previous
              </button>
            )}

            <span className="px-4 py-2">
              Page {currentPage} of {totalPages}
            </span>

            {currentPage < totalPages && (
              <button
                className="px-4 py-2 mx-2 bg-gray-300 rounded"
                onClick={handleNextPage}
              >
                Next
              </button>
            )}
          </div>
        </div>
      )}

      <FiltersModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onFilterChange={handleFilterChange}
        applyFilters={() =>
          applyFilters(
            data,
            filteredData,
            filters,
            setFilteredData,
            setCurrentPage,
            setModalOpen
          )
        }
        filters={filters}
      />
    </div>
  );
};

export default Home;
