import React, { useState } from 'react';
import { SiChatbot } from "react-icons/si";
import { useLocation } from 'react-router-dom';
import Chatbot from '../../pages/Home/index';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  const [showChat, setShowChat] = useState(true);
  const location = useLocation();

  const handleChatClick = () => {
    setShowChat(true);
  };

  const handleCloseChat = () => {
    setShowChat(false);
  };

  const isSpecialRoute = location.pathname === '/admin' || location.pathname === '/operator';

  return (
    <div className="flex flex-col min-h-screen">
      {!isSpecialRoute && <Navbar />}
      <div className="flex-grow">
        {children}
      </div>
      <div className="fixed bottom-10 right-10 z-50">
        {!showChat && (
          <div
            className="w-16 h-16 border-[1px] bg-[#00FFFF] hover:cursor-pointer cursor-pointer border-[#33ECFF] rounded-full flex justify-center items-center text-4xl text-[#0000FF]"
            onClick={handleChatClick}
          >
            <SiChatbot />
          </div>
        )}
        {showChat && <Chatbot showChat={showChat} handleCloseChat={handleCloseChat} />}
      </div>
    </div>
  );
};

export default Layout;