import React, { useState, useEffect } from "react";
import UserDynamicForm from "./userFilterForm";
import TipResponse from "./TipResponse";
import get_payment_details from '../../../payment/getPaymentDetails'
import Cookies from "js-cookie";
import SubscriptionPage from "./userSubscriptionPage";

const OperatorResponse = ({ data: operatorResponse, setMessages }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [data, setData] = useState(operatorResponse);
  const [isResponseClicked, setIsResponseClicked] = useState(null);
  const [subscription, setSubscription] = useState(null)

  const addressErrorMessage =
    "The addresses searched are either not in contiguous lots or not located in the same block or otherwise not considered the same zoning lot per ZR 12-10";
  // useEffect(() => {
  //   if (typeof operatorResponse !== "string") {
  //     function splitFarData(Olddata) {
  //       const updatedFar = [];

  //       Olddata.far.forEach((item) => {
  //         const response1Parts =
  //           item[
  //             "Response 1 - this is included in the first response, before the user selects any filters."
  //           ]?.split("$") || [];
  //         const response25Parts =
  //           item[
  //             "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75"
  //           ]?.split("$") || [];
  //         // const faValueParts =
  //         //   item[
  //         //     "FA Formula: Value = Lot Area (from ZOLA) x Values (from line above) (SF)"
  //         //   ]?.split("$") || [];
  //         const faValueFormula =
  //           item[
  //             "FA Formula: Value = Lot Area (from ZOLA) x Values (from line above) (SF)"
  //           ] || "";
  //         const faValueParts =
  //           (typeof faValueFormula === "string" && faValueFormula.split("$")) ||
  //           [];

  //         const index = item["index"];

  //         if (
  //           response1Parts.length >= 2 &&
  //           faValueParts.length >= 2 &&
  //           response25Parts.length >= 2
  //         ) {
  //           updatedFar.push({
  //             ...item,
  //             "Response 1 - this is included in the first response, before the user selects any filters.":
  //               response1Parts[0].trim(),
  //             "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75":
  //               response25Parts[0].trim(),
  //             "FA Formula: Value = Lot Area (from ZOLA) x Values (from line above) (SF)":
  //               faValueParts[0].trim(),
  //             index: `${index}_0`,
  //           });

  //           updatedFar.push({
  //             ...item,
  //             "Response 1 - this is included in the first response, before the user selects any filters.":
  //               response1Parts[1].trim(),
  //             "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75":
  //               response25Parts[1].trim(),
  //             "FA Formula: Value = Lot Area (from ZOLA) x Values (from line above) (SF)":
  //               faValueParts[1].trim(),
  //             index: `${index}_1`,
  //           });
  //         } else {
  //           updatedFar.push(item);
  //         }
  //       });

  //       return { ...Olddata, far: updatedFar };
  //     }

  //     const updatedData = splitFarData(operatorResponse);
  //     setData(updatedData);
  //   }
  // }, [operatorResponse]);

  const anchorTag = (inputString, link) => {
    const regex = /\[([^\]]+)\]/;
    const match = inputString?.match(regex);

    if (match) {
      const textInsideBrackets = match[1];
      const outputString = inputString?.replace(
        match[0],
        `<a href="${link}" target="_blank" style="color: blue">${textInsideBrackets}</a>`
      );
      return `<div>${outputString}</div>`;
    }
    return `<div>${inputString}</div>`;
  };

  const handleUserFilter = async() => {

    const token = Cookies.get("token")
    const subscription = await get_payment_details(token);
    if (!subscription) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          jsx: <SubscriptionPage />,
        },
      ]);
      return;
    }

    const filterString = localStorage.getItem("filter");
    const userFilter = JSON.parse(filterString);

    const desiredKeys = [
      "Filter 1 (User to Select Preference)",
      "Filter 2 (User to Select Preference)",
      "Filter 2.1 (User to Select Preference)",
      "Filter 3 (User to Select Preference)",
      "index",
    ];
    const userFilterData = userFilter.filter;
    const extractedData = Object.keys(userFilterData).map((sheetName) => {
      const filterData = Array.isArray(userFilterData[sheetName])
        ? userFilterData[sheetName].map((item) => {
            const extractedItem = {};
            desiredKeys.forEach((key) => {
              extractedItem[key] = item[key] || "";
            });
            return extractedItem;
          })
        : [];

      return {
        sheetName,
        filter: filterData,
      };
    });

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "bot",
        jsx: (
          <UserDynamicForm
            extractedData={extractedData}
            setMessages={setMessages}
            userFilterData={userFilterData}
          />
        ),
      },
    ]);
    return;
  };

  const handleButtonClick = (index, responseType) => {
    setActiveIndex((prevIndex) =>
      prevIndex === `${index}-${responseType}`
        ? null
        : `${index}-${responseType}`
    );
  };

  const title =
    "Residential FAR and Max Allowed Dwelling Units Preliminary Analysis:";

  if (typeof data === "string") {
    return (
      <div className="text-xl font-semibold mb-4">{addressErrorMessage}</div>
    );
  }

  const responseTip = (tipText, tipLink) => {
    const tipValue = anchorTag(tipText, tipLink);
    return tipValue;
  };

  return (
    <>
      <div className="text-xl font-semibold mb-4">{title}</div>
      {Object.keys(data)
        .slice(1, 3)
        .map((item, index) => (
          <React.Fragment key={index}>
            <div className="text-xl font-semibold my-4">
              {item?.toUpperCase()}
            </div>
            {data[item]?.map((val, idx) => {
              if (
                !["", undefined, " ", null].includes(
                  val[
                    "Response 1 - this is included in the first response, before the user selects any filters."
                  ]
                ) ||
                !["", undefined, " ", null].includes(
                  val[
                    "Response 2 - response included in the first results. Round to closest natural number."
                  ]
                ) ||
                (item == "far" &&
                  ![" ", "", null, undefined].includes(
                    val[
                      "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75"
                    ]
                  ))
              ) {
                return (
                  <div
                    key={idx}
                    className="flex flex-col justify-start items-start p-3 bg-blue-200 my-2 py-2 px-4 rounded-xl"
                  >
                    {!["", undefined, " ", null].includes(
                      val[
                        "Response 1 - this is included in the first response, before the user selects any filters."
                      ]
                    ) && (
                      <div>
                        <div className="p-2 rounded-md">
                          <TipResponse
                            val={
                              val[
                                "Response 1 - this is included in the first response, before the user selects any filters."
                              ]
                            }
                            setIsResponseClicked={setIsResponseClicked}
                            uniqueIdentity={`${val["index"]}-response1`}
                          />
                          <button
                            className="ml-1"
                            onClick={() =>
                              handleButtonClick(val["index"], "response1")
                            }
                          >
                            (i)
                          </button>
                        </div>
                        <p>
                          {activeIndex === `${val["index"]}-response1` &&
                            val["Additional Notes and Conditions (i)"] && (
                              <div
                                className="border-2 border-white p-1 rounded my-1"
                                dangerouslySetInnerHTML={{
                                  __html: anchorTag(
                                    val["Additional Notes and Conditions (i)"],
                                    val["Web Link"]
                                  ),
                                }}
                              />
                            )}
                        </p>
                      </div>
                    )}
                    <p>
                      {isResponseClicked === `${val["index"]}-response1` &&
                        val["Tip 1"] && (
                          <div
                            className="border-2 border-white p-1 rounded my-1 w-fit"
                            dangerouslySetInnerHTML={{
                              __html: responseTip(
                                val["Tip 1"],
                                val["Link for Tip 1"]
                              ),
                            }}
                          />
                        )}
                    </p>
                    {!["", undefined, " ", null].includes(
                      val[
                        "Response 2 - response included in the first results. Round to closest natural number."
                      ]
                    ) && (
                      <div>
                        <div className="p-2 rounded-md">
                          <TipResponse
                            val={
                              val[
                                "Response 2 - response included in the first results. Round to closest natural number."
                              ]
                            }
                            setIsResponseClicked={setIsResponseClicked}
                            uniqueIdentity={`${val["index"]}-response2`}
                          />
                          <button
                            className="ml-1"
                            onClick={() =>
                              handleButtonClick(val["index"], "response2")
                            }
                          >
                            (i)
                          </button>
                        </div>
                        <p>
                          {activeIndex === `${val["index"]}-response2` &&
                            val["Additional Notes and Conditions (i)"] && (
                              <div
                                className="border-2 border-white p-1 rounded my-1"
                                dangerouslySetInnerHTML={{
                                  __html: anchorTag(
                                    val["Additional Notes and Conditions (i)"],
                                    val["Web Link"]
                                  ),
                                }}
                              />
                            )}
                        </p>
                      </div>
                    )}
                    <p>
                      {isResponseClicked === `${val["index"]}-response2` &&
                        val["Tip 2"] && (
                          <div
                            className="border-2 border-white p-1 rounded my-1 w-fit"
                            dangerouslySetInnerHTML={{
                              __html: responseTip(
                                val["Tip 2"],
                                val["Link for Tip 2"]
                              ),
                            }}
                          />
                        )}
                    </p>
                    {item === "far" &&
                      ![" ", null, undefined].includes(
                        val[
                          "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75"
                        ]
                      ) && (
                        <div>
                          <div className="p-2 rounded-md">
                            <TipResponse
                              val={
                                val[
                                  "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75"
                                ]
                              }
                              setIsResponseClicked={setIsResponseClicked}
                              uniqueIdentity={`${val["index"]}-response2.5`}
                            />
                            <button
                              className="ml-1"
                              onClick={() =>
                                handleButtonClick(val["index"], "response2.5")
                              }
                            >
                              (i)
                            </button>
                          </div>
                          <p>
                            {activeIndex === `${val["index"]}-response2.5` &&
                              val["Additional Notes and Conditions (i)"] && (
                                <div
                                  className="border-2 border-white p-1 rounded my-1"
                                  dangerouslySetInnerHTML={{
                                    __html: anchorTag(
                                      val[
                                        "Additional Notes and Conditions (i)"
                                      ],
                                      val["Web Link"]
                                    ),
                                  }}
                                />
                              )}
                          </p>
                          <p>
                            {isResponseClicked ===
                              `${val["index"]}-response2.5` &&
                              val["Tip 2.5"] && (
                                <div
                                  className="border-2 border-white p-1 rounded my-1 w-fit"
                                  dangerouslySetInnerHTML={{
                                    __html: responseTip(
                                      val["Tip 2.5"],
                                      val["Link for Tip 2.5"]
                                    ),
                                  }}
                                />
                              )}
                          </p>
                        </div>
                      )}
                  </div>
                );
              }
              return null;
            })}
          </React.Fragment>
        ))}
      <button
        className="px-4 py-2 bg-blue-500 text-white rounded-md mt-4 font-normal"
        onClick={handleUserFilter}
      >
        {/* Click Next to know more */}
        Click Next to know more - pay here
      </button>
    </>
  );
};

export default OperatorResponse;
