import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/404";
import Login from "./pages/Admin/Login";
import Register from "./pages/Admin/Register";
import ProtectedRoute from "./components/protectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Admin/Dashboard";
import EmailVerified from "./pages/User/EmailVerify";
import DynamicForm from "./pages/Home/components/filterForm";
import UserDynamicForm from "./pages/Home/components/userFilterForm";
import Home from "./website/pages/Home/Home";
import Navbar from "./website/components/Navbar";
import Layout from "./website/components/Layout";
import DynamicFormWebsite from "./website/components/FilterForm";
import AdminPage from "./website/pages/Admin/Admin";
import Auth from "./pages/Admin/Login";

const App = () => {
  return (
    <div>
      <Layout>
        <Routes>
          <Route path="/admin-login" element={<Login />} />
          <Route path="/admin-register" element={<Register />} />
          <Route
            path="/admin"
            element={<ProtectedRoute element={Dashboard} />}
          />
          <Route path="/operator" element={<ProtectedRoute element={AdminPage} />} />

          <Route path="/" element={<Home />} />
          <Route
            path="/website/operator-filter/:id"
            element={<DynamicFormWebsite />}
          />

          <Route path="operator-filter/:id" element={<DynamicForm />} />
          <Route path="user-filter/:id" element={<UserDynamicForm />} />
          <Route path="/verifyEmail" element={<EmailVerified />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>

      <ToastContainer />
    </div>
  );
};

export default App;
