import React from "react";

const TipResponse = ({ val, setIsResponseClicked, uniqueIdentity }) => {


  const handleClick = () => {
    setIsResponseClicked((prev) =>
      prev === uniqueIdentity ? null : uniqueIdentity
    );
  };

  const renderResponse = (response) => {
    const regex = /\[(.*?)\]/;
    const match = response?.match(regex);

    if (match) {
      const textBefore = response?.slice(0, match.index);
      const textInside = match[1];
      const textAfter = response?.slice(match.index + match[0].length);
      return (
        <>
          {textBefore}
          <button
            style={{
              background: "none",
              color: "blue",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleClick}
          >
            {textInside}
          </button>
          <span dangerouslySetInnerHTML={{ __html: textAfter.replace(/\r\n/g, '<br />') }}></span>
        </>
      );
    }

    return response;
  };

  return (
    <span>
      {renderResponse(val)}
    </span>
  );
};

export default TipResponse;
