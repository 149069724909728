import React from "react";

const ChatInput = ({
  inputValue,
  setInputValue,
  isVerified,
  handleSendMessage,
  verifyEmail,
  loading,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (isVerified) {
        handleSendMessage();
      } else {
        verifyEmail();
      }
    }
  };

  return (
    <div>
      {loading && (
        <p className="text-center">
          Please be patient while we do the preliminary zoning analysis for you.
          Please{" "}
          <a
            className="text-blue-700"
            href="https://www.youtube.com/watch?v=W0hXyG8F-_Y"
            target="__blank"
          >
            click here
          </a>{" "}
          to learn more about our chat.
        </p>
      )}
      <div className="bg-white p-4 flex items-center">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder={loading ? "Waiting for response..." : "Type here"}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            className={`w-full px-2 py-2 border border-gray-300 outline-none rounded-l-lg ${
              loading ? "bg-gray-200" : ""
            }`}
            disabled={loading}
          />
        </div>
        <button
          className={`w-[150px] h-12 bg-blue-500 text-white rounded-r-lg ml-2 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={isVerified ? handleSendMessage : verifyEmail}
          disabled={loading}
        >
          {isVerified ? "Send" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
