  import { createSlice } from "@reduxjs/toolkit";

  const initialState = {
    properties: [],
  };

  const propertySlice = createSlice({
    name: "properties",
    initialState,
    reducers: {
      addProperties: (state, action) => {
        const newProperties = action.payload.filter(
          (newProperty) => !state.properties.some(
            (storedProperty) => storedProperty._id === newProperty._id
          )
        );
        state.properties.push(...newProperties);
      },

      clearProperties: (state) => {
        state.properties = [];
      },
    },
  });

  export const { addProperties, clearProperties } = propertySlice.actions;

  export default propertySlice.reducer;
