import React from "react";

const InputField = ({ label, type, name, value, onChange, placeholder, error }) => {
  return (
    <div className="flex flex-col">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required
        className="py-3 mt-1 px-4 text-base outline-none rounded-xl"
      />
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
};

export default InputField;
