import React, { useRef, useEffect } from "react";

const ChatMessages = ({
  messages,
  isVerified,
  connectToProfessional,
  connected,
}) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="flex-1 overflow-y-auto p-4 bg-white">
      <div className="mb-4">
        {!isVerified && (
          <p className="text-white p-3 rounded-r-lg rounded-b-lg bg-blue-500 mb-2 max-w-full">
            Please enter your email to verify your identity
          </p>
        )}
      </div>
      <div className="flex flex-col space-y-2">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`p-3 ${msg.sender !== "user" ? "w-11/12" : "w-fit"} ${
              msg.sender !== "user"
                ? "bg-gray-100 rounded-tr-lg rounded-tl-lg self-end text-gray-800 ml-8"
                : "bg-blue-100 rounded-br-lg rounded-bl-lg self-start text-blue-800"
            }`}
          >
            {msg.isButton ? (
              <button
                disabled={connected}
                onClick={() => connectToProfessional()}
                className={`bg-blue-500 text-white p-2 rounded-lg ${
                  connected ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Chat with Professional
              </button>
            ) : msg.jsx ? (
              msg.jsx
            ) : msg.html ? (
              <div dangerouslySetInnerHTML={{ __html: msg.html }} />
            ) : (
              <span>{msg.text}</span>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ChatMessages;
