import React, { useEffect, useState } from "react";
import CouponDetails from "./components/CouponDetails";
import GenerateCoupon from "./components/GenerateCoupon";
import { getApi } from "../../../Api/Api";
import Cookies from "js-cookie"

const Coupon = () => {
  const [coupons, setCouponList] = useState([]);

  useEffect(() => {
    const token = Cookies.get("authToken")
    const getCoupons = async () => {
      const response = await getApi("admin/get-coupons", token);
      if (response?.data?.data) {
        setCouponList(response.data.data);
      }
    };
    getCoupons();
  }, []);


  return (
    <article className="generate_coupon m-10">
      <main className="grid grid-cols-5 gap-10">
        <aside className="col-span-1">
          <GenerateCoupon  setCouponList={setCouponList} coupons={coupons}/>
        </aside>
        <aside className="col-span-4">
          <CouponDetails setCouponList ={setCouponList} coupons ={coupons}/>
        </aside>
      </main>
    </article>
  );
};

export default Coupon;
