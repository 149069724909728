import React from "react";
import FormTable from "../../components/FormTable";

const AdminPage = () => {
  return (
    <div>
      <FormTable />
    </div>
  );
};

export default AdminPage;
