import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    user: {},
  },
  reducers: {
    adminData: (state, action) => {
      const payload = action.payload;
      state.admin = { ...payload };
    },resetAdmin: (state) => {
        state.admin = undefined; 
    },
  },
});

export const { adminData, resetAdmin } = adminSlice.actions;
export default adminSlice.reducer;
