import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = Cookies.get("authToken");

  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/admin-login" />
  );
};

export default ProtectedRoute;
