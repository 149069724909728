import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import InputField from "../../../../components/UI/InputFIeld";
import { postApi } from "../../../../Api/Api";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "admin",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.password) newErrors.password = "Password is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const response = await postApi(formData, "admin/signUp");
      if (response.token) {
        Cookies.set("authToken", response.token, { expires: 1.5 / 24 });
        toast.success("Register successfully !");
        navigate("/admin");
      }else{
        toast.error(response?.message);
      }
    } else {
      setErrors(validationErrors);
      toast.error("Please fill all the required fields");
    }
  };

  return (
    <form className="w-[80%] mx-auto" onSubmit={handleSubmit}>
      <div className="px-5 py-5 flex flex-col gap-8">
        <InputField
          label="First Name"
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          placeholder="Enter your first name"
          error={errors.firstName}
        />
        <InputField
          label="Last Name"
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Enter your last name"
          error={errors.lastName}
        />
        <InputField
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Enter your Email"
          error={errors.email}
        />
        <InputField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          placeholder="Enter your Password"
          error={errors.password}
        />
      </div>
      <div className="flex flex-col px-5 py-5 items-center">
        <button
          className="w-full h-10 bg-[#0000FF] py-1 rounded-lg text-xl text-white mb-3"
          type="submit"
        >
          Sign Up
        </button>
        <p className="">
          Already have an account?{" "}
          <NavLink to={"/admin-login"} className="text-[#6495ED]">
            Log In
          </NavLink>
        </p>
      </div>
    </form>
  );
};

export default RegisterForm;
