import React, { useState, useEffect, useRef } from "react";
import { keyMappings } from "./staticData";
import html2pdf from "html2pdf.js";
import TipResponse from "./TipResponse";

const PdfForUserResponse = ({ data, setShowPdf }) => {
  const [Tip, setTip] = useState({});
  const [tipActive, setTipActive] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeFarIndex, setActiveFarIndex] = useState("");
  const [isResponseClicked, setIsResponseClicked] = useState(null);
  const pdfRef = useRef(null);

  useEffect(() => {
    const generatePDF = () => {
      const options = {
        margin: 0.5,
        filename: `${data.address}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      html2pdf().from(pdfRef.current).set(options).save();
      html2pdf().from(pdfRef.current).set(options).outputPdf('blob').then((pdfBlob) => {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
      });
      setShowPdf(false);
    };
    generatePDF();
  }, []);

  let userFilter = data?.userFilter;

  const replaceKeys = (userFilter, mappings) => {
    Object.values(userFilter).map((dataArray, index) => {
      dataArray?.forEach((item) => {
        Object.keys(mappings).forEach((oldKey) => {
          if (item?.hasOwnProperty(oldKey)) {
            const newKey = mappings[oldKey];
            item[newKey] = item[oldKey];
            delete item[oldKey];
          }
        });
      });
    });
    return userFilter;
  };

  userFilter = replaceKeys(userFilter, keyMappings);

  function splitFarData(userFilter) {
    const updatedFar = [];

    userFilter.far.forEach((item) => {
      const response1Parts = item["Response 1"]
        ? item["Response 1"].split("$")
        : [];
      const response25Parts = item["Response 2.5"]
        ? item["Response 2.5"].split("$")
        : [];
      const faValueParts = item["FA Value"]
        ? `${item["FA Value"]}`.split("$")
        : [];

      if (
        response1Parts.length >= 2 &&
        faValueParts.length >= 2 &&
        response25Parts.length >= 2
      ) {
        updatedFar.push({
          ...item,
          "Response 1": response1Parts[0].trim(),
          "Response 2.5": response25Parts[0].trim(),
          "FA Value": faValueParts[0].trim(),
        });

        updatedFar.push({
          ...item,
          "Response 1": response1Parts[1].trim(),
          "Response 2.5": response25Parts[1].trim(),
          "FA Value": faValueParts[1].trim(),
        });
      } else {
        updatedFar.push(item);
      }
    });

    userFilter.far = updatedFar;

    return userFilter;
  }
  userFilter = splitFarData(userFilter);


  const title =
    "Residential FAR and Max Allowed Dwelling Units Preliminary Analysis:";
  const titleMapping = {
    far: "Floor Area and Density Calculations (i)",
    height: "Height regulations (i)",
    yard: "Yards regulations (i)",
    use_group: "Use Group",
  };
  const [collapsedSections, setCollapsedSections] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const toggleCollapse = (section) => {
    console.log("seee", section);
    setCollapsedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  useEffect(() => {
    const initialCollapsedState = {};
    Object.keys(userFilter).forEach((key) => {
      initialCollapsedState[key] = key !== "far";
    });
    setCollapsedSections(initialCollapsedState);
  }, [userFilter]);

  const toggleDescription = () => {
    setShowDescription((prev) => !prev);
  };

  console.log({ userFilter });

  const anchorTag = (inputString, link) => {
    const regex = /\[([^\]]+)\]/;
    const match = inputString?.match(regex);

    if (match) {
      const textInsideBrackets = match[1];
      const outputString = inputString?.replace(
        match[0],
        `<a href="${link}" target="_blank" style="color: blue">${textInsideBrackets}</a>`
      );
      const result = `<div>${outputString}</div>`;
      return result;
    }
    return `<div>${inputString}</div>`;
  };

  const handleButtonClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleGroupClick = (group, data) => {
    const index = data["index"];
    const romanToDigit = {
      I: 1,
      II: 2,
      III: 3,
      IV: 4,
      V: 5,
      VI: 6,
      VII: 7,
      VIII: 8,
      IX: 9,
      X: 10,
    };

    let userInput = romanToDigit[`${group?.trim()?.toUpperCase()}`];
    const tipNumber = `Tip ${userInput}`;
    const linkNumber = `Link for Tip ${userInput}`;
    const tip = data[tipNumber];
    const link = data[linkNumber];
    setTip({
      tip,
      link,
    });
    setTipActive((prevIndex) => (prevIndex == index ? null : index));
  };

  const showTipWithLink = (content, link) => {
    const regex = /\[([^\]]+)\]/;
    const match = content?.match(regex);
    if (match) {
      const text = match ? match[1] : "";
      const linkHTML = `<a href="${link}" target="_blank" style="color: blue">${text}</a>`;
      const updatedStr = content?.replace(regex, linkHTML);
      return `<div>${updatedStr}</div>`;
    }
    return `<div>${content}</div>`;
  };

  const handleFarClick = (identifier) => {
    setActiveFarIndex((prevIndex) =>
      prevIndex === identifier ? null : identifier
    );
  };

  const responses = [
    "Response 1",
    "Response 2",
    "Response 2.5",
    "Response 3",
    "Response 4",
    "Response 4.5",
    "Response 5",
    "Response 6",
    "Response 7",
    "Response 8",
    "Response 9",
    "Response 10",
    "Response 11",
    "Response 12",
  ];

  return (
    <div ref={pdfRef}>
      <div className="text-xl font-semibold mb-4">{title}</div>
      <div>Address: {data.address}</div>
      {Object.keys(userFilter)?.map((item, index) => (
        <React.Fragment key={index}>
          <div className="text-md font-semibold my-4 flex items-center">
            <button
              onClick={() => toggleCollapse(item)}
              className="mr-2 inline-block px-1.5 py-0.5  border border-gray-400 rounded"
            >
              <span className="text-gray-600">
                {collapsedSections[item] ? "+" : "-"}
              </span>
            </button>
            {titleMapping[item.toLowerCase()] || item.toUpperCase()}
          </div>

          <>
            {userFilter[item].length !== 0 ? (
              userFilter[item]?.map(
                (val, idx) =>
                  responses.some((responseKey) => val[responseKey?.trim()]) && (
                    <div
                      key={idx}
                      className="flex flex-col justify-start items-start my-4 bg-blue-200 rounded-xl p-1.5"
                    >
                      {item === "far" && (
                        <div className="bg-blue-200 my-2 py-2 px-4 rounded-xl">
                          {responses.map((responseKey, idx) => {
                            const responseValue = val[responseKey?.trim()];
                            const showResponse =
                              responseValue !== "" &&
                              responseValue !== null &&
                              responseValue !== undefined;
                            const uniqueIdentifier = `${val["index"]}-${responseKey}`;

                            return (
                              showResponse && (
                                <div key={uniqueIdentifier} className="my-2">
                                  <div>
                                    {responseValue &&
                                    [
                                      "Response 2",
                                      "Response 4",
                                      "Response 6",
                                      "Response 8",
                                      "Response 10",
                                      "Response 12",
                                    ].includes(responseKey) ? (
                                      <TipResponse
                                        val={responseValue}
                                        setIsResponseClicked={
                                          setIsResponseClicked
                                        }
                                        uniqueIdentity={uniqueIdentifier}
                                      />
                                    ) : (
                                      <TipResponse
                                        val={responseValue}
                                        setIsResponseClicked={
                                          setIsResponseClicked
                                        }
                                        uniqueIdentity={uniqueIdentifier}
                                      />
                                    )}
                                    <button
                                      className="ml-1"
                                      onClick={() =>
                                        handleFarClick(uniqueIdentifier)
                                      }
                                    >
                                      (i)
                                    </button>
                                    {activeFarIndex === uniqueIdentifier && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: anchorTag(
                                            val[
                                              "Additional Notes and Conditions (i)"
                                            ],
                                            val["Web Link"]
                                          ),
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      )}

                      {item === "height" && (
                        <div>
                          {[...Array(12)].map((_, index) => {
                            const responseKey = `Response ${index + 1}`;
                            return val[responseKey] ? (
                              <React.Fragment key={index}>
                                <div className="my-2">
                                  {/* {val[responseKey]} */}
                                  <TipResponse
                                    val={val[responseKey]}
                                    setIsResponseClicked={setIsResponseClicked}
                                    uniqueIdentity={`${responseKey}-${val["index"]}-${item}`}
                                  />
                                  <button
                                    className="ml-1"
                                    onClick={() =>
                                      handleButtonClick(val["index"])
                                    }
                                  >
                                    (i)
                                  </button>
                                  {activeIndex === val["index"] && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: anchorTag(
                                          val[
                                            "Additional Notes and Conditions (i)"
                                          ],
                                          val["Web Link"]
                                        ),
                                      }}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            ) : null;
                          })}
                        </div>
                      )}

                      {item === "yard" && (
                        <div>
                          {[...Array(12)].map((_, index) => {
                            const responseKey = `Response ${index + 1}`;
                            return val[responseKey] &&
                              val[responseKey] !== "All" ? (
                              <React.Fragment key={index}>
                                <div className="my-2">
                                  {/* {val[responseKey]} */}
                                  <TipResponse
                                    val={val[responseKey]}
                                    setIsResponseClicked={setIsResponseClicked}
                                    uniqueIdentity={`${responseKey}-${val["index"]}-${item}`}
                                  />
                                  <button
                                    className="ml-1"
                                    onClick={() =>
                                      handleButtonClick(val["index"])
                                    }
                                  >
                                    (i)
                                  </button>
                                  {activeIndex === val["index"] && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: anchorTag(
                                          val[
                                            "Additional Notes and Conditions"
                                          ],
                                          val["Web Link"]
                                        ),
                                      }}
                                    />
                                  )}
                                </div>
                              </React.Fragment>
                            ) : null;
                          })}
                        </div>
                      )}

                      {item === "use_group" && (
                        <div>
                          {[...Array(12)].map((_, index) => {
                            const responseKey = `Response ${index + 1}`;
                            return val[responseKey] ? (
                              <React.Fragment key={index}>
                                <div className="my-2">
                                  {val[responseKey]
                                    ?.split(/(\[.*?\])/g)
                                    .map((part, i) => {
                                      const match = part?.match(/\[(.*?)\]/);
                                      return match ? (
                                        <button
                                          className="text-blue-600"
                                          key={i}
                                          onClick={() =>
                                            handleGroupClick(match[1], val)
                                          }
                                        >
                                          {match[1]}{" "}
                                        </button>
                                      ) : (
                                        part
                                      );
                                    })}
                                  <button
                                    className="ml-1"
                                    onClick={() =>
                                      handleButtonClick(val["index"])
                                    }
                                  >
                                    (i)
                                  </button>
                                </div>
                                {activeIndex === val["index"] && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: anchorTag(
                                        val[
                                          "Additional Notes and Conditions (i)"
                                        ],
                                        val["Web Link"]
                                      ),
                                    }}
                                  />
                                )}
                                {tipActive === val["index"] && (
                                  <div
                                    className="my-2"
                                    dangerouslySetInnerHTML={{
                                      __html: showTipWithLink(
                                        Tip["tip"],
                                        Tip["link"]
                                      ),
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ) : null;
                          })}
                        </div>
                      )}
                    </div>
                  )
              )
            ) : (
              <p className="my-2">No Data Available</p>
            )}
          </>
        </React.Fragment>
      ))}
      <div className="mt-6">
        <button
          onClick={toggleDescription}
          className="px-4 py-2 text-black font-bold -ml-4"
        >
          Disclaimer (i)
        </button>

        <div className="mt-4 p-4 bg-gray-100 border border-gray-300 rounded">
          Please note that this is an AI-generated preliminary zoning analysis
          and as such none of the presented responses are intended to be used as
          a zoning analysis. Such information shall be checked and verified by a
          licensed professional.
        </div>
      </div>
    </div>
  );
};

export default PdfForUserResponse;
