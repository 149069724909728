import React from "react";
import RegisterForm from "./components/RegisterForm";

const Register = () => {
  return (
    <div className="w-[100%] h-screen flex justify-center items-center">
      <div className="w-[30%] h-auto shadow-md rounded-lg bg-[#f7f7fa]">
        <h2 className="ml-[32%] pt-4 text-3xl font-bold">
          Create Admin Account
        </h2>
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;
