export const keyMappings = {
  "Response 1 - this is included in the first response, before the user selects any filters.":
    "Response 1",
  "Response 2 - response included in the first results. Round to closest natural number.":
    "Response 2",
  "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75":
    "Response 2.5",
  "Response 3 - this response is included based on the filters that the user will select.":
    "Response 3",
  "Response 4 - this response is included based on the filters that the user will select.":
    "Response 4",
  "Response 4.5 - this response is included in the first response, before the user selects any filters. Round to highest natural number ≥ .75 and to lowest natural number < .75":
    "Response 4.5",
  "Response 5 - this response is included based on the filters that the user will select.":
    "Response 5",
  "Response 6 - this response is included based on the filters that the user will select.":
    "Response 6",
  "Response 7 - this response is included based on the filters that the user will select.":
    "Response 7",
  "Response 8 - this response is included based on the filters that the user will select.":
    "Response 8",
  "Response 9 - this response is included based on the filters that the user will select.":
    "Response 9",
  "Response 10 - this response is included based on the filters that the user will select.":
    "Response 10",
  "Response 11 - this response is included based on the filters that the user will select.":
    "Response 11",
  "Response 12 - this response is included based on the filters that the user will select.":
    "Response 12",
  "FA Formula: Value = Lot Area (from ZOLA) x Values (from line above) (SF)":
    "FA Value",
};
