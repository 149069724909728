import React, { useEffect } from "react";
import Cookies from "js-cookie";
import Chat from "./components/chat";

const Home = ({ showChat, handleCloseChat }) => {
  useEffect(() => {
    Cookies.remove("is_email_verified");
    Cookies.remove("token");
    localStorage.removeItem("bot_messages");
    localStorage.removeItem("filter");
  }, []);

  return (
    <div className="bg-[#61121f] ">
      {showChat && <Chat handleCloseChat={handleCloseChat} />}
    </div>
  );
};

export default Home;