import React, { useState, useEffect, useRef } from "react";
import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import ChatInput from "./ChatInput";
import Cookies from "js-cookie";
import { postApi } from "../../../Api/Api";
import UserDynamicForm from "./userFilterForm";
import OperatorResponse from "./operatorResponse";
import SubscriptionCard from "./userSubscriptionPage";

const Chat = ({ handleCloseChat }) => {
  const [inputValue, setInputValue] = useState("");
  // const [inactivityTimeout, setInactivityTimeout] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isVerified, setVerify] = useState(false);
  const gmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const prevData = [];
  const ws = useRef(null);
  let flag = false;
  let inactivityTimer = null;

  let pingInterval = useRef(null);

  useEffect(() => {
    const filteredData = messages.map((item) => {
      const newItem = { ...item };
      if ("jsx" in newItem) {
        delete newItem.jsx;
      }
      return newItem;
    });

    localStorage.setItem("bot_messages", JSON.stringify(filteredData));
  }, [messages]);

  const isTextAddress = (inputData) => {
    const isAddress =
      /^\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?$/gm.test(
        inputData
      ) ||
      /^(\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?)(\s*\+\s*\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?)*$/gm.test(
        inputData
      );
    return isAddress;
  };

  useEffect(() => {
    return () => {
      if (inactivityTimer) clearTimeout(inactivityTimer);
    };
  }, []);

  useEffect(() => {
    const connectWebSocket = () => {
      console.log("Connecting to WebSocket...",process.env.REACT_APP_SOCKET_URL);
      ws.current = new WebSocket(process.env.REACT_APP_SOCKET_URL);

      ws.current.onopen = () => {
        const token = Cookies.get("token");
        if (token) {
          ws.current.send(
            JSON.stringify({
              type: "REAUTHENTICATE",
              data: { token },
            })
          );
        }
        console.log("WebSocket connected.");
        // Start a ping interval to keep the connection alive
        pingInterval.current = setInterval(() => {
          if (ws.current.readyState === WebSocket.OPEN) {
            console.log("Sending ping to server");
            ws.current.send(JSON.stringify({ type: "PING" }));
          }
        }, 30000);
      };

      ws.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        handleWebSocketMessage(message);
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        clearInterval(pingInterval.current);
      };

      ws.current.onclose = () => {
        console.log("WebSocket closed. Attempting to reconnect...");
        clearInterval(pingInterval.current);
        if (document.visibilityState === "visible") {
          setTimeout(() => {
            if (ws.current?.readyState === WebSocket.CLOSED) {
              connectWebSocket();
            }
          }, 1000);
        }
      };
    };

    const handleVisibilityChange = () => {
      const userActive = document.visibilityState === "visible";

      if (userActive && ws.current?.readyState === WebSocket.CLOSED) {
        console.log("Tab focused. Reconnecting WebSocket...");
        connectWebSocket();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    connectWebSocket();

    // On refresh or unmount, close WebSocket and clean up
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (ws.current) {
        ws.current.close();
        clearInterval(pingInterval.current);
      }
      localStorage.removeItem("connectedToProfessional");
    };
  }, []);

  const connectToProfessional = async () => {
    console.log("prevvvData", prevData);
    const response = await postApi(
      {
        to: "9172950831231231",
        templateName: "Notification",
        languageCode: "en_US",
        prevData: prevData,
      },
      "operator/send-notification"
    );
    console.log("Connected to professional", response);
    if (response.success) {
      setConnected(true);
      setLoading(true);

      localStorage.setItem("messageStatus", "Connecting with a Professional.");
      setLoadingText(localStorage.getItem("messageStatus"));

      localStorage.setItem("connectedToProfessional", "true");
      const timeoutTimestamp = new Date(Date.now() + 10 * 60 * 1000);
      localStorage.setItem("timeoutTimestamp", timeoutTimestamp.toString());
      setTimeout(handleTimeout, 10 * 60 * 1000);
      console.log("Connected to professional", response);
    }
  };

  const handleTimeout = () => {
    localStorage.setItem("messageStatus", "");
    if (
      localStorage.getItem("chatEndedByOperator") === "true" ||
      localStorage.getItem("operatorMessage") === "true"
    ) {
      localStorage.setItem("chatEndedByOperator", "");
      return;
    }
    setConnected(false);
    setLoading(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "bot",
        text: "Unfortunately, I couldn't find an available professional at this moment. We will contact you via email within the next 24 hours. If you would like to be contacted via phone, please provide a phone number, and someone will contact you during business hours.",
      },
    ]);
    localStorage.removeItem("connectedToProfessional");
    localStorage.removeItem("timeoutTimestamp");
  };

  const handleWebSocketMessage = (message) => {
    const { type, data, text } = message;

    switch (type) {
      case "REAUTHENTICATION_RESPONSE": {
        if (text) {
          const token = Cookies.get("token");
          const allMessage = localStorage.getItem("bot_messages");
          const botMessage = JSON.parse(allMessage);
          const latestMessage =
            botMessage?.length > 0 ? botMessage[botMessage?.length - 1] : null;

          if (latestMessage && latestMessage?.sender === "user") {
            const latestText = latestMessage?.text;
            if (isTextAddress(latestText)) {
              ws.current.send(
                JSON.stringify({
                  type: "ADDRESS_QUERY",
                  data: { address: latestText, token: token },
                })
              );
            }
          }
        }
        break;
      }

      case "EMAIL_ALREADY_VERIFIED":
        setVerify(true);
        Cookies.set("is_email_verified", true, { expires: 1.5 / 1440 });
        Cookies.set("token", data.token);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: data.text },
        ]);
        break;
      case "PING":
        console.log("Ping received from server, sending pong");
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
          ws.current.send(JSON.stringify({ type: "PONG" }));
        }
        break;

      case "PONG":
        break;
      case "EMAIL_SENT":
        setLoading(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: data.text },
        ]);
        break;

      case "EMAIL_VERIFIED":
        setLoading(false);
        setVerify(true);
        Cookies.set("is_email_verified", true, { expires: 1.5 / 1440 });
        Cookies.set("token", data.token);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            text: " Your email has been successfully verified, please feel free to ask anything related to the NYC Zoning Resolution or enter an address if you would like to know a preliminary analysis of what can be built as-of-right in such address. Please use format 120 BROADWAY, 10271 when searching an address. You can also enter more than one address at the same time to receive a combined result, but please make sure all addresses are in contiguous lots located in the same block",
          },
        ]);
        break;

      case "END_CHAT":
        setLoading(false);
        if (localStorage.getItem("connectedToProfessional") == "true") {
          setLoadingText(false);
          clearTimeout(handleTimeout);
          setConnected(false);
          // localStorage.setItem("chatEndedByOperator", "true");
          // localStorage.setItem("connectedToProfessional", "");
          localStorage.removeItem("connectedToProfessional");
          localStorage.setItem("operatorMessage", "");
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "operator", text },
          ]);
        }
        break;

      case "OPERATOR_RESPONSE":
        setLoading(false);
        if (localStorage.getItem("connectedToProfessional") == "true") {
          localStorage.setItem("operatorMessage", "true");
          if (localStorage.getItem("operatorMessage") == "true") {
            resetInactivityTimer();
          }
          localStorage.setItem("messageStatus", "");
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "operator", text },
          ]);
          localStorage.setItem(
            "messages",
            JSON.stringify([...messages, { sender: "operator", text }])
          );
        }
        break;

      case "BOT_RESPONSE":
        if (text !== undefined) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "bot", text },
          ]);
        }
        // console.log("4-------------");
        break;

      case "FILTERED_ADDRESS":
        setLoading(false);
        console.log({ data });
        const filter = { filter: data };
        localStorage.setItem("filter", JSON.stringify(filter));

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "bot",
            jsx: <OperatorResponse data={data} setMessages={setMessages} />,
          },
        ]);
        break;

      case "ANSWER":
        setLoading(false);
        console.log({ text });
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text },
        ]);
        if (
          text.toLowerCase() ==
          "i am not able to understand your question. chat with a professional or write a different question or search for an address. please use format 120 BROADWAY, 10271".toLowerCase()
        ) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "bot", isButton: true },
          ]);
        }
        break;

      case "PAYMENT_SUCCESS":
        setLoading(false);
        console.log("payment done", data);
        if (data?.isSubscribed) {
          const filterString = localStorage.getItem("filter");
          const userFilter = JSON.parse(filterString);

          const desiredKeys = [
            "Filter 1 (User to Select Preference)",
            "Filter 2 (User to Select Preference)",
            "Filter 2.1 (User to Select Preference)",
            "Filter 3 (User to Select Preference)",
            "index",
          ];
          const userFilterData = userFilter?.filter;
          const extractedData = Object.keys(userFilterData).map((sheetName) => {
            const filterData = Array.isArray(userFilterData[sheetName])
              ? userFilterData[sheetName]?.map((item) => {
                  const extractedItem = {};
                  desiredKeys.forEach((key) => {
                    extractedItem[key] = item[key] || "";
                  });
                  return extractedItem;
                })
              : [];

            return {
              sheetName,
              filter: filterData,
            };
          });
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              sender: "bot",
              jsx: (
                <UserDynamicForm
                  extractedData={extractedData}
                  setMessages={setMessages}
                  userFilterData={userFilterData}
                />
              ),
            },
          ]);
          break;
        }
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", jsx: <SubscriptionCard /> },
        ]);
        break;

      case "ERROR":
        setLoading(false);
        console.error("Error from server:", data);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: text },
        ]);
        break;

      default:
        console.warn("Unknown message type:", type);
        break;
    }
  };

  const resetInactivityTimer = () => {
    if (inactivityTimer) clearTimeout(inactivityTimer);

    inactivityTimer = setTimeout(() => {
      if (localStorage.getItem("userTrigger") != "true")
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "system",
            text: "We haven't heard from you for some time. Do you wish to continue to chat?",
          },
        ]);
      localStorage.removeItem("userTrigger", "");

      setTimeout(() => {
        localStorage.removeItem("connectedToProfessional");
      }, 5000);
    }, 5 * 60 * 1000);
  };

  const handleSendMessage = () => {
    const inputData = inputValue.trim();
    if (!inputData) return;

    localStorage?.removeItem("userTrigger");
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: inputData },
    ]);
    setInputValue("");
    if (localStorage.getItem("connectedToProfessional") !== "true") {
      setLoading(true);
    }

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const isAddress =
        /^\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?$/gm.test(
          inputData
        ) ||
        /^(\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?)(\s*\+\s*\d{1,5}(?:-\d{1,5})?\s[a-zA-Z0-9\s\-.,]+(?:,\s[a-zA-Z\s]+)?(?:,\s[A-Z]{2})?\s?\d{5}(?:\s[a-zA-Z\s]+)?)*$/gm.test(
          inputData
        );
      console.log("oooooooo", localStorage.getItem("connectedToProfessional"));
      const messageType = localStorage.getItem("connectedToProfessional")
        ? "USER_MESSAGE"
        : isAddress
        ? "ADDRESS_QUERY"
        : "QUESTION_QUERY";
      let messageData = localStorage.getItem("connectedToProfessional")
        ? { userMessage: inputData }
        : isAddress
        ? { address: inputData }
        : { question: inputData };
      if (localStorage.getItem("connectedToProfessional") == "true") {
        localStorage.setItem("userTrigger", "true");
      }
      messageData = {
        ...messageData,
        token: Cookies.get("token"),
      };
      console.log("message", messageData);

      prevData.push(messageData);
      console.log("prevvvvvData", prevData);
      ws.current.send(JSON.stringify({ type: messageType, data: messageData }));
    } else {
      console.error("WebSocket is not open. Unable to send message.");
    }
  };

  const verifyEmail = () => {
    const inputData = inputValue.trim();

    if (!inputData) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Please enter your email to verify." },
      ]);
      return;
    }
    if (!gmailRegex.test(inputData)) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Please enter a valid email." },
      ]);
      return;
    }

    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      const data = { email: inputData };
      ws.current.send(JSON.stringify({ type: "VERIFY_EMAIL", data }));
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: inputData },
    ]);
    setInputValue("");
  };

  return (
    <div className="fixed bottom-10 right-10 sm:right-5  w-[30%] h-[80%] max-2xl:w-[30%] max-xl:w-[45%] max-lg:w-[45%] max-sm:w-[70%]  shadow-lg flex flex-col bg-gray-100 rounded-lg overflow-hidden">
      <ChatHeader handleCloseChat={handleCloseChat} />
      <ChatMessages
        messages={messages}
        isVerified={isVerified}
        connectToProfessional={connectToProfessional}
        connected={connected}
      />
      {localStorage.getItem("messageStatus") ==
        "Connecting with a Professional." && (
        <h2 className="flex justify-center">{loadingText}</h2>
      )}

      <ChatInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        isVerified={isVerified}
        handleSendMessage={handleSendMessage}
        verifyEmail={verifyEmail}
        loading={loading}
      />
      {/* {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="loader">Loading...</div> 
        </div>
      )} */}
    </div>
  );
};

export default Chat;
