import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
// import { Link } from "react-router-dom";

const EmailVerified = () => {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "success") {
      Cookies.set("is_email_verified", true);
    }
  }, [location]);

  return (
    <div className="flex items-center justify-center h-screen bg-blue-50">
      <div className="text-center p-8 border border-gray-300 rounded-lg bg-green-50 shadow-md">
        <h1 className="text-3xl font-bold text-green-600 mb-4">
          Email Verification Done
        </h1>
        <p className="text-gray-600">
          Thank you for verifying your email. Please return to the chat to
          continue.
        </p>
        {/* <Link to="/" className="text-blue-600 hover:text-blue-800 underline">
          Click here to return to chat
        </Link> */}
      </div>
    </div>
  );
};

export default EmailVerified;
