import React from "react";
import { IoIosRemoveCircle } from "react-icons/io";

const FileItem = ({
  file,
  index,
  fileDragging,
  fileDropping,
  removeFile,
  dragEnter,
  dragStart,
}) => {
  return (
    <div
      className={`relative flex flex-col items-center overflow-hidden text-center w-[206px] h-[132px] rounded-lg cursor-move select-none ${
        fileDragging === index ? "border-blue-600" : ""
      }`}
      draggable="true"
      onDragStart={(e) => dragStart(e, index)}
      onDragEnd={() => dragStart(null)}
      onDragEnter={(e) => dragEnter(e, index)}
    >
      <button
        className="absolute top-0 right-0 bg-white w-[19.49px] h-[19.49px] rounded-full z-50 p-1 focus:outline-none"
        type="button"
        onClick={() => removeFile(index)}
      >
        <IoIosRemoveCircle className="text-[#ED7D2DFF] top-0 right-0 absolute text-2xl" />
      </button>
      <div className="absolute inset-0 z-0 flex items-center justify-center w-full h-full bg-gray-200 rounded-lg">
        <p className="text-[#171A1FFF]">{file.name}</p>
      </div>
      <div
        className={`absolute inset-0 z-40 transition-colors duration-300 ${
          fileDropping === index && fileDragging !== index
            ? "bg-blue-200 bg-opacity-80"
            : ""
        }`}
      ></div>
    </div>
  );
};

export default FileItem;
