import { useState } from "react";
import Label from "../../../../components/UI/Label";
import { postApi } from "../../../../Api/Api";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const DURATIONS = [
  { label: "Forever", value: "forever" },
  { label: "Once", value: "once" },
  { label: "Repeating", value: "repeating" },
];

const GenerateCoupon = ({ setCouponList, coupons }) => {
  const [couponName, setCouponName] = useState("");
  const [duration, setDuration] = useState("");
  const [durationInMonths, setDurationInMonths] = useState("");
  const [percentOff, setPercentOff] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name: couponName,
      duration,
      validity: duration === "repeating" ? durationInMonths : undefined,
      discount: percentOff,
    };

    const token = Cookies.get("authToken");
    const response = await postApi(payload, "admin/create-coupon", token);
    if (response?.data) {
      const UpdatedCoupons = [...coupons, response.data];
      setCouponList(UpdatedCoupons);
      toast.success("Coupon created successfully!");

      setCouponName("");
      setDuration("");
      setPercentOff("");
      setDurationInMonths("");
    } else {
      toast.error("Failed to create coupon. Please try again.");
    }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-6">Generate Coupon</h2>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col space-y-4 items-start justify-center"
      >
        <div className="flex flex-col w-full">
          <Label label={"Coupon Name"} htmlFor="couponName" />
          <input
            id="couponName"
            type="text"
            placeholder="Coupon Name"
            value={couponName}
            onChange={(e) => setCouponName(e.target.value)}
            className="border p-2 rounded-xl w-full"
            required
          />
        </div>
        <div className="flex flex-col  w-full">
          <Label label={"Duration"} htmlFor="duration" />
          <select
            id="duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            className="border p-2 rounded-xl w-full"
            required
          >
            <option value="" disabled>Select duration</option>
            {DURATIONS.map((item, index) => (
              <option key={index} value={item.value}>{item.label}</option>
            ))}
          </select>
        </div>
        {duration === "repeating" && (
          <div className="flex flex-col  w-full">
            <Label label={"Duration in Months"} htmlFor="durationInMonths" />
            <input
              id="durationInMonths"
              type="number"
              min={1}
              placeholder="Duration in Months"
              value={durationInMonths}
              onChange={(e) => setDurationInMonths(Number(e.target.value))}
              className="border p-2 rounded-xl"
              required
            />
          </div>
        )}
        <div className="flex flex-col  w-full">
          <Label label={"Percent Off"} htmlFor="percentOff" />
          <input
            id="percentOff"
            type="number"
            step="0.1"
            placeholder="Percent Off"
            value={percentOff}
            onChange={(e) => setPercentOff(Number(e.target.value))}
            className="border p-2 rounded-xl"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-green-800 text-white p-2 mt-5 rounded-2xl"
        >
          Generate
        </button>
      </form>
    </>
  );
};

export default GenerateCoupon;
