const getApi = async (endpoint, token = null) => {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/website/${endpoint}`;


    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(URL, {
      method: "GET",
      headers: headers,
    });
    const res = await response.json();
    return res;
  } catch (e) {
    console.log(e);
  }
};

const postApi = async (payload, endpoint, token = null) => {
  try {
    const URL = `${process.env.REACT_APP_API_URL}/website/${endpoint}`;

    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(URL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });

    const res = await response.json();

    return res;
  } catch (e) {
    console.log(e);
  }
};


export { getApi ,postApi};
