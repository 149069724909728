import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "./noopStorage";
import adminReducer from "./slices/adminSlice";
import properyReducer from "./slices/propertySlice";
import operatorReducer from "./slices/operatorSlice";

import { persistReducer, persistStore } from "redux-persist";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  admin: adminReducer,
  properties: properyReducer,
  operator: operatorReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
