import { getApi, postApi } from "../Api/api";
import { addProperties } from "../../store/slices/propertySlice";

export const mapLotAreaToProperties = (properties, lotAreaData) => {
  const lotAreaMap = lotAreaData.reduce((acc, item) => {
    acc[item.address] = {
      lotArea: item.lotArea,
      zoningDistricts: item.zoningDistricts,
    };
    return acc;
  }, {});

  const mappedProperties = properties.map((property) => {
    const lotAreaInfo = lotAreaMap[property.name] || {
      lotArea: "N/A",
      zoningDistricts: "N/A",
    };
    return {
      ...property,
      lotArea: lotAreaInfo.lotArea,
      zoningDistricts: lotAreaInfo.zoningDistricts,
    };
  });

  return mappedProperties;
};

export const extractFARValue = (responseString) => {
  const match = responseString.match(
    /Residential (?:Base |Max )?FAR = ([\d.]+)/
  );
  return match ? match[1] : "TBD";
};

export const extractFAValue = (responseString) => {
  const match =
    responseString.match(/Residential (?:Base |Max )?FA\s*=\s*([\d.]+)/) ||
    responseString.match(/\[Residential (?:Base |Max )?FA\]\s*=\s*([\d.]+)/);
  return match ? match[1] : "TBD";
};

export const extractMaxApartmentsAllowed = (responseString) => {
  const match = responseString.match(/\[Max Apartments Allowed\]\s*=\s*(\d+)/);
  return match ? match[1] : "TBD";
};

export const mapZolaDataToProperties = (properties, zolaData) => {
  const zolaDataMap = zolaData.reduce((acc, item) => {
    acc[item.address] = item.data;
    return acc;
  }, {});

  return properties.map((property) => {
    const zolaInfo = zolaDataMap[property.name] || [];

    const farEntries = zolaInfo.map(
      (info) =>
        info[
          "Response 1 - this is included in the first response, before the user selects any filters."
        ]
    );
    const faEntries = zolaInfo.map(
      (info) =>
        info[
          "Response 2 - response included in the first results. Round to closest natural number."
        ]
    );
    const maxApartmentEntries = zolaInfo.map(
      (info) =>
        info[
          "Response 2.5 - response included in the first results. Round to highest natural number ≥ .75 and to lowest natural number < .75"
        ]
    );

    const FARs = farEntries
      .map((entry) => extractFARValue(entry))
      .filter((value) => value !== "TBD");
    const FAs = faEntries
      .map((entry) => extractFAValue(entry))
      .filter((value) => value !== "TBD");
    const maxApartments = maxApartmentEntries
      .map((entry) => extractMaxApartmentsAllowed(entry))
      .filter((value) => value !== "TBD");

    return {
      ...property,
      FARs: FARs.length > 0 ? FARs : ["TBD"],
      FAs: FAs.length > 0 ? FAs : ["TBD"],
      maxApartments: maxApartments.length > 0 ? maxApartments : ["TBD"],
    };
  });
};

export const mergeData = (data, filteredData) => {
  const filteredDataMap = filteredData.reduce((acc, item) => {
    acc[item.name] = item;
    return acc;
  }, {});

  return data.map((property) => {
    const additionalData = filteredDataMap[property.name] || {};
    return {
      ...property,
      ...additionalData,
    };
  });
};

export const applyFilters = (
  data,
  filteredData,
  filters,
  setFilteredData,
  setCurrentPage,
  setModalOpen
) => {
  const mergedData = mergeData(data, filteredData);

  let filtered = mergedData;

  if (filters.zip_code) {
    console.log("Filtering by zip code:", filters.zip_code);

    filtered = filtered.filter(
      (property) => property?.address?.postalCode === filters.zip_code
    );
  }

  if (filters.price_min || filters.price_max) {
    filtered = filtered.filter((property) => {
      const price = parseInt(property?.price?.replace(/[^0-9]/g, ""), 10);
      const minPrice = filters.price_min ? parseInt(filters.price_min, 10) : 0;
      const maxPrice = filters.price_max
        ? parseInt(filters.price_max, 10)
        : Infinity;
      return price >= minPrice && price <= maxPrice;
    });
  }

  if (filters.listing_updates_min || filters.listing_updates_max) {
    try {
      const minDate = filters.listing_updates_min
        ? new Date(filters.listing_updates_min)
        : new Date(0);
      const maxDate = filters.listing_updates_max
        ? new Date(filters.listing_updates_max)
        : new Date();
      minDate.setUTCHours(0, 0, 0, 0);
      maxDate.setUTCHours(23, 59, 59, 999);

      filtered = filtered.filter((property) => {
        if (property?.listingUpdated) {
          const listingDate = property?.listingUpdated
            ? new Date(property.listingUpdated)
            : null;
          return listingDate >= minDate && listingDate <= maxDate;
        }
        return false;
      });
    } catch (error) {
      console.error(
        "Invalid date format:",
        filters.listing_updates_min,
        filters.listing_updates_max
      );
    }
  }
  if (filters.max_apartments_min || filters.max_apartments_max) {
    const minApartments = filters.max_apartments_min
      ? parseInt(filters.max_apartments_min, 10)
      : 0;
    const maxApartments = filters.max_apartments_max
      ? parseInt(filters.max_apartments_max, 10)
      : Infinity;

    filtered = filtered.filter((property) => {
      return property?.maxApartments?.some((maxApartment) => {
        if (maxApartment !== "TBD") {
          const maxApt = parseInt(maxApartment, 10);
          const isInRange = maxApt >= minApartments && maxApt <= maxApartments;
          return isInRange;
        }
        return false;
      });
    });
  }

  if (filters.residential_far) {
    filtered = filtered.filter((property) => {
      return property?.FARs?.some(
        (FAR) =>
          FAR !== "TBD" &&
          parseFloat(FAR) === parseFloat(filters.residential_far)
      );
    });
  }

  if (filters.lot_area_min || filters.lot_area_max) {
    const minLotArea = filters.lot_area_min
      ? parseInt(filters.lot_area_min, 10)
      : 0;
    const maxLotArea = filters.lot_area_max
      ? parseInt(filters.lot_area_max, 10)
      : Infinity;

    filtered = filtered.filter((property) => {
      const lotArea = property?.lotArea !== "TBD" ? property.lotArea : null;
      return lotArea !== null && lotArea >= minLotArea && lotArea <= maxLotArea;
    });
  }
  if (filters.residential_fa) {
    filtered = filtered.filter((property) => {
      return property?.FAs?.some((FA) => {
        if (FA !== "TBD") {
          const faValue = parseFloat(FA);
          const filterFaValue = parseFloat(filters.residential_fa);
          return !isNaN(faValue) && faValue === filterFaValue;
        }
        return false;
      });
    });
  }

  if (filters.zoning_district) {
    filtered = filtered.filter((property) => {
      const zoningDistricts = property?.zoningDistricts;

      if (Array.isArray(zoningDistricts)) {
        const matches = zoningDistricts.some((district) => {
          return district !== "N/A" && district === filters.zoning_district;
        });
        return matches;
      } else if (typeof zoningDistricts === "string") {
        return (
          zoningDistricts !== "N/A" &&
          zoningDistricts === filters.zoning_district
        );
      } else {
        return false;
      }
    });
  }
  setFilteredData(filtered);
  setCurrentPage(1);
  setModalOpen(false);
};

export const resetFilters = async (
  setFilters,
  setLoading,
  setData,
  setFilteredData,
  setCurrentPage
) => {
  setFilters({
    zip_code: "",
    max_apartments_allowed: "",
    residential_far: "",
    residential_fa: "",
    price: "",
    lot_area: "",
    zoning_district: "",
    listing_updates: "",
  });

  setLoading(true);

  try {
    const response = await getApi("address/get-address");
    if (response?.data) {
      const newData = response.data;

      const lotAreaResponse = await getApi("address/lotarea");
      const newLotArea = lotAreaResponse?.data || [];

      const zolaResponse = await getApi("operator/operator-get");
      const newZolaData = zolaResponse?.data || [];

      let mappedProperties = mapLotAreaToProperties(newData, newLotArea);
      mappedProperties = mapZolaDataToProperties(mappedProperties, newZolaData);

      setData(newData);
      setFilteredData(mappedProperties);
    }
  } catch (error) {
    console.error("Error resetting filters:", error);
  } finally {
    setLoading(false);
    setCurrentPage(1);
  }
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const isPropertyInStore = (property, properties) => {
  return properties.some((storedProperty) => {
    return storedProperty._id === property._id;
  });
};
