import React, { useState, useEffect } from "react";
import { keyMappings } from "./staticData";
import UserResponse from "./userResponse";
import { getApi, postApi } from "../../../Api/Api";
import Cookies from "js-cookie";

const filterKeys = [
  "Filter 1 (User to Select Preference)",
  "Filter 2 (User to Select Preference)",
  "Filter 3 (User to Select Preference)",
];

const matchingData = {
  "Filter 1 (User to Select Preference)":
    "Please select your project preferences.",
  "Filter 2 (User to Select Preference)": "Please select the projected use(s).",
  "Filter 3 (User to Select Preference)":
    "Please select the type of residences.",
};

const userFilter = {
  far: [],
  height: [],
  yard: [],
  use_group: [],
};

const requiredCounts = {
  far: 3,
  yard: 3,
  height: 3,
  // use_group: 2,
};

function getRequiredCombinations(data, counts) {
  const combinationCount = {};
  data.forEach((item) => {
    const key = `${item.index}-${item.sheetName}`;
    if (!combinationCount[key]) {
      combinationCount[key] = { ...item, count: 0 };
    }
    combinationCount[key].count += 1;
  });
  return Object.values(combinationCount).filter((item) => {
    return counts[item.sheetName] && item.count === counts[item.sheetName];
  });
}

const UserDynamicForm = ({ extractedData, setMessages, userFilterData }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [notSureChecked, setNotSureChecked] = useState({});
  const [firstTab, setFirstTab] = useState([]);
  const [tipIndex, setTipIndex] = useState(false);
  const [userData, setUserData] = useState({ address: "", token: "" });

  useEffect(() => {
    const getFirstTab = async () => {
      const response = await getApi(`operator/get-first-tab`);
      setFirstTab(response?.data);
    };
    const token = Cookies.get("token");
    setUserData({ address: userFilterData.address, token: token });
    getFirstTab();
  }, []);

  const restructureData = (data) => {
    return data.map((sheet) => ({
      sheetName: sheet.sheetName,
      filter: sheet.filter.map((filterItem) => {
        const {
          "Filter 1 (User to Select Preference)": filter1,
          "Filter 2 (User to Select Preference)": filter2,
          "Filter 2.1 (User to Select Preference)": subFilter2,
          "Filter 3 (User to Select Preference)": filter3,
          index,
        } = filterItem;

        return {
          "Filter 1 (User to Select Preference)": filter1,
          "Filter 2": {
            value: filter2,
            subFilter: subFilter2,
          },
          "Filter 3 (User to Select Preference)": filter3,
          index,
        };
      }),
    }));
  };

  const restructuredData = restructureData(extractedData);

  const mergeFilters = (data) => {
    const mergedData = {
      "Filter 1 (User to Select Preference)": [],
      "Filter 2 (User to Select Preference)": [],
      "Filter 2.1 (User to Select Preference)": [],
      "Filter 3 (User to Select Preference)": [],
    };

    data.forEach((sheet) => {
      sheet.filter.forEach((item) => {
        mergedData["Filter 1 (User to Select Preference)"].push({
          key: item["Filter 1 (User to Select Preference)"],
          index: item.index,
          sheetName: sheet.sheetName,
        });

        mergedData["Filter 2 (User to Select Preference)"].push({
          key: item["Filter 2"].value,
          subFilter: item["Filter 2"].subFilter,
          index: item.index,
          sheetName: sheet.sheetName,
        });

        mergedData["Filter 3 (User to Select Preference)"].push({
          key: item["Filter 3 (User to Select Preference)"],
          index: item.index,
          sheetName: sheet.sheetName,
        });
      });
    });

    return mergedData;
  };

  const mergedData = mergeFilters(restructuredData);

  const handleCheckboxChange = (filterKey, itemKey) => {
    const combinedKey = `${filterKey}-${itemKey}`;
    if (selectedFilters.includes(combinedKey)) {
      setSelectedFilters(selectedFilters.filter((key) => key !== combinedKey));
      setFinalArray(
        finalArray.filter((item) => item.combinedKey !== combinedKey)
      );
    } else {
      setSelectedFilters([...selectedFilters, combinedKey]);

      console.log({ mergedData: mergedData });

      const newItems = mergedData[filterKey]
        .filter((item) => item.key === itemKey)
        .map((item) => ({
          ...item,
          combinedKey,
          filterKey,
        }));

      console.log({ newItems });

      setFinalArray([...finalArray, ...newItems]);
    }
  };

  const handleNotSureChange = (filterKey) => {
    const isNotSureChecked = notSureChecked[filterKey] || false;
    setNotSureChecked((prevState) => ({
      ...prevState,
      [filterKey]: !isNotSureChecked,
    }));

    if (!isNotSureChecked) {
      // Clear previously selected filters for this specific filterKey
      const filterToRemove = mergedData[filterKey].map(
        (item) => `${filterKey}-${item.key}`
      );

      setSelectedFilters((prevSelectedFilters) =>
        prevSelectedFilters.filter((key) => !filterToRemove.includes(key))
      );

      setFinalArray((prevFinalArray) =>
        prevFinalArray.filter((item) => item.filterKey !== filterKey)
      );

      // Now, select all checkboxes for this filter
      const newSelectedFilters = mergedData[filterKey]
        .filter((item) => item.key !== "All" && item.key !== "")
        .map((item) => `${filterKey}-${item.key}`);

      setSelectedFilters((prevSelectedFilters) => [
        ...prevSelectedFilters,
        ...newSelectedFilters,
      ]);

      const newItems = mergedData[filterKey]
        .filter((item) => item.key !== "All" && item.key !== "")
        .map((item) => ({
          ...item,
          combinedKey: `${filterKey}-${item.key}`,
          filterKey,
        }));

      setFinalArray((prevFinalArray) => [...prevFinalArray, ...newItems]);
    } else {
      // If "Not Sure" is unchecked, clear all selections for this filter
      const filterToRemove = mergedData[filterKey].map(
        (item) => `${filterKey}-${item.key}`
      );

      setSelectedFilters((prevSelectedFilters) =>
        prevSelectedFilters.filter((key) => !filterToRemove.includes(key))
      );

      setFinalArray((prevFinalArray) =>
        prevFinalArray.filter((item) => item.filterKey !== filterKey)
      );
    }
  };

  const handleUserFilterSubmit = async (e) => {
    e.preventDefault();
    await postApi(userData, "payment/update-subscription");
    const allData = [];
    filterKeys.forEach((filterKey) => {
      allData.push(
        ...mergedData[filterKey]
          .filter((item) => item.key === "All")
          .map((item) => ({
            ...item,
            combinedKey: `${filterKey}-All`,
            filterKey,
          }))
      );
    });

    const uniqueData = Array.from(
      new Map(
        finalArray.map((item) => [
          `${item.sheetName}-${item.index}-${item.combinedKey}`,
          item,
        ])
      ).values()
    );

    const completeFinalArray = [...uniqueData, ...allData];

    const combination = getRequiredCombinations(
      completeFinalArray,
      requiredCounts
    );

    userFilter["far"] = userFilterData?.far.filter((el) => {
      return combination.some(
        (item) => item.index === el.index && item.sheetName === "far"
      );
    });

    userFilter["height"] = userFilterData?.height.filter((el) => {
      return combination.some(
        (item) => item.index === el.index && item.sheetName === "height"
      );
    });

    userFilter["yard"] = userFilterData?.yard.filter((el) => {
      return combination.some(
        (item) => item.index === el.index && item.sheetName === "yard"
      );
    });

    userFilter["use_group"] = userFilterData?.use_group;

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        sender: "bot",
        jsx: (
          <UserResponse
            userFilter={userFilter}
            address={userFilterData.address}
          />
        ),
      },
    ]);
  };

  const handleNotSubSureChange = (filterKey) => {
    const isChecked = !notSureChecked[filterKey];
    setNotSureChecked({ ...notSureChecked, [filterKey]: isChecked });
    if (isChecked) {
      const allItems = mergedData[filterKey].map((item) => {
        const combinedKey = `${filterKey}-${item.key}-${
          item.subFilter || "All"
        }`;
        return {
          ...item,
          combinedKey,
          filterKey,
        };
      });
      const combinedKeys = allItems.map((item) => item.combinedKey);
      const newItems = allItems.filter(
        (item) =>
          !finalArray.some(
            (existingItem) => existingItem.combinedKey === item.combinedKey
          )
      );
      setSelectedFilters([...selectedFilters, ...combinedKeys]);
      setFinalArray([...finalArray, ...newItems]);
    } else {
      const remainingFilters = selectedFilters.filter(
        (key) => !key.startsWith(`${filterKey}-`)
      );
      setSelectedFilters(remainingFilters);
      setFinalArray(finalArray.filter((item) => item.filterKey !== filterKey));
    }
  };

  const handleCheckboxChangeSub = (
    filterKey,
    itemKey,
    subFilter,
    sheetName
  ) => {
    const combinedKey = `${filterKey}-${itemKey}-${subFilter || "All"}`;
    console.log({ combinedKey });
    if (selectedFilters.includes(combinedKey)) {
      setSelectedFilters(selectedFilters.filter((key) => key !== combinedKey));
      setFinalArray(
        finalArray.filter((item) => item.combinedKey !== combinedKey)
      );
    } else {
      setSelectedFilters([...selectedFilters, combinedKey]);

      const newItems = mergedData[filterKey].filter((item) => {
        if (item.sheetName == "height") {
          return (
            item.key === itemKey &&
            item.subFilter.trim().length == 0 &&
            item.sheetName === "height"
          );
        } else {
          return (
            item.key === itemKey &&
            item.subFilter === subFilter &&
            item.sheetName !== "height"
          );
        }
      });

      console.log({ newItems });

      const newData = newItems.map((item) => ({
        ...item,
        combinedKey:
          item.subFilter == subFilter ? combinedKey : `${filterKey}-${itemKey}`,
        filterKey,
      }));

      const uniqueData = Array.from(
        new Map(
          newData.map((item) => [
            `${item.sheetName}-${item.index}-${item.combinedKey}`, // Composite key
            item,
          ])
        ).values()
      );

      console.log({ uniqueData });
      setFinalArray([...finalArray, ...uniqueData]);
    }
  };

  const showTipWithLink = (content, link) => {
    if (content === undefined) {
      return `<div></div>`;
    }
    const regex = /\[([^\]]+)\]/;
    const match = content?.match(regex);
    if (match) {
      const text = match ? match[1] : "";
      const linkHTML = `<a href="${link}" target="_blank" style="color: blue">${text}</a>`;
      const updatedStr = content?.replace(regex, linkHTML);
      return `<div>${updatedStr}</div>`;
    }
    return `<div>${content}</div>`;
  };

  const addTips = (value) => {
    if (value == "") {
      return;
    }
    const getKey = firstTab?.map((item) => item[value]?.trim());
    const getLink = getKey[0];
    if (getLink) {
      const linkStartIndex = getLink.indexOf("https://");
      const link = getLink.substring(linkStartIndex);
      // const text = getLink.substring(0, linkStartIndex).trim();
      const text = getLink
        .substring(0, linkStartIndex)
        .trim()
        .replace(/:$/, "");
      return showTipWithLink(text, link);
    }
    return null;
  };

  const handleTip = (index) => {
    setTipIndex((prvIdx) => (prvIdx === index ? null : index));
  };

  return (
    <form
      className="max-w-xl mx-auto p-6 bg-gray-50 rounded-lg shadow-lg space-y-6"
      onSubmit={handleUserFilterSubmit}
    >
      {filterKeys.map((filterKey, index) => (
        <div key={index} className="mb-4 text-xl">
          <strong>{matchingData[filterKey]}</strong>
          {filterKey === "Filter 2 (User to Select Preference)" ? (
            <>
              {Object.entries(
                mergedData[filterKey].reduce((acc, item) => {
                  if (item.key !== "All" && item.key !== "") {
                    if (!acc[item.key]) {
                      acc[item.key] = [];
                    }
                    acc[item.key].push(item);
                  }
                  return acc;
                }, {})
              ).map(([parentKey, items], parentIndex) => (
                <div key={parentKey} className="mb-2">
                  <div className="flex items-center my-2">
                    <input
                      type="checkbox"
                      id={`checkbox-${filterKey}-${parentKey}`}
                      checked={selectedFilters.includes(
                        `${filterKey}-${parentKey}-All`
                      )}
                      onChange={() =>
                        handleCheckboxChangeSub(
                          filterKey,
                          parentKey,
                          "All",
                          items.sheetName
                        )
                      }
                      className="mr-2"
                    />
                    <label
                      htmlFor={`checkbox-${filterKey}-${parentKey}`}
                      className="text-base	"
                    >
                      {/* {parentKey} */}
                      {parentKey?.includes("(i)")
                        ? parentKey?.split("(i)")[0]
                        : parentKey}
                      <button
                        type="button"
                        onClick={() => handleTip(`${parentKey}-${parentIndex}`)}
                        className="ml-1"
                      >
                        (i)
                      </button>
                    </label>
                  </div>
                  <div>
                    {tipIndex === `${parentKey}-${parentIndex}` && (
                      <div
                        className="text-base rounded-md"
                        dangerouslySetInnerHTML={{
                          __html: addTips(
                            parentKey.includes("(i)")
                              ? parentKey.split("(i)")[0]?.trim()
                              : parentKey?.trim()
                          ),
                        }}
                      />
                    )}
                  </div>
                  <div className="flex flex-col ml-4">
                    {[...new Set(items.map((item) => item.subFilter))]
                      .filter(
                        (subFilter) => subFilter !== "" && subFilter !== "All"
                      )
                      .map((subFilter, subFilterIndex) => (
                        <div>
                          <div
                            key={subFilterIndex}
                            className="flex items-center my-1"
                          >
                            <input
                              type="checkbox"
                              id={`checkbox-${filterKey}-${parentKey}-${subFilterIndex}`}
                              checked={selectedFilters.includes(
                                `${filterKey}-${parentKey}-${subFilter}`
                              )}
                              onChange={() =>
                                handleCheckboxChangeSub(
                                  filterKey,
                                  parentKey,
                                  subFilter,
                                  items.sheetName
                                )
                              }
                              className="mr-2"
                              disabled={
                                !selectedFilters.includes(
                                  `${filterKey}-${parentKey}-All`
                                )
                              } // Disable if the parent checkbox is not checked
                            />
                            <label
                              htmlFor={`checkbox-${filterKey}-${parentKey}-${subFilterIndex}`}
                              className="text-base	"
                            >
                              {/* {subFilter}*/}
                              {subFilter?.includes("(i)")
                                ? subFilter?.split("(i)")[0]
                                : subFilter}
                              <button
                                type="button"
                                onClick={() =>
                                  handleTip(`${subFilter}-${parentIndex}`)
                                }
                                className="ml-1"
                              >
                                (i)
                              </button>
                            </label>
                          </div>
                          <div>
                            {tipIndex === `${subFilter}-${parentIndex}` && (
                              <div
                                className="text-base rounded-md"
                                dangerouslySetInnerHTML={{
                                  __html: addTips(
                                    subFilter.includes("(i)")
                                      ? subFilter.split("(i)")[0]?.trim()
                                      : subFilter?.trim()
                                  ),
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

              {/* Add "Not Sure at this time" checkbox */}
              <div className="flex items-center mt-2">
                <input
                  type="checkbox"
                  id={`not-sure-${filterKey}`}
                  checked={notSureChecked[filterKey] || false}
                  onChange={() => handleNotSubSureChange(filterKey)}
                  className="mr-2"
                />
                <label htmlFor={`not-sure-${filterKey}`} className="text-base	">
                  Not Sure at this time
                </label>
              </div>
            </>
          ) : (
            <>
              {mergedData[filterKey]
                .filter(
                  (item, itemIndex, self) =>
                    self.findIndex((t) => t.key === item.key) === itemIndex &&
                    item.key !== "All" &&
                    item.key !== ""
                )
                .map((item, itemIndex) => (
                  <div>
                    <div key={itemIndex} className="flex items-center my-2">
                      <input
                        type="checkbox"
                        id={`checkbox-${filterKey}-${itemIndex}`}
                        checked={selectedFilters.includes(
                          `${filterKey}-${item.key}`
                        )}
                        onChange={() =>
                          handleCheckboxChange(filterKey, item.key)
                        }
                        className="mr-2"
                      />
                      <label
                        htmlFor={`checkbox-${filterKey}-${itemIndex}`}
                        className="text-base"
                      >
                        {/* {item.key} */}
                        {item?.key?.includes("(i)")
                          ? item?.key?.split("(i)")[0]
                          : item?.key}
                        <button
                          type="button"
                          onClick={() => handleTip(`${filterKey}-${itemIndex}`)}
                          className="ml-1"
                        >
                          (i)
                        </button>
                      </label>
                    </div>
                    <div>
                      {tipIndex === `${filterKey}-${itemIndex}` && (
                        <div
                          className="text-base rounded-md"
                          dangerouslySetInnerHTML={{
                            __html: addTips(
                              item?.key?.includes("(i)")
                                ? item?.key?.split("(i)")[0]?.trim()
                                : item?.key?.trim()
                            ),
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}

              {/* Add "Not Sure at this time" checkbox */}
              <div className="flex items-center mt-2">
                <input
                  type="checkbox"
                  id={`not-sure-${filterKey}`}
                  checked={notSureChecked[filterKey] || false}
                  onChange={() => handleNotSureChange(filterKey)}
                  className="mr-2"
                />
                <label htmlFor={`not-sure-${filterKey}`} className="text-base">
                  Not Sure at this time
                </label>
              </div>
            </>
          )}
        </div>
      ))}

      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded-lg"
      >
        Submit
      </button>
    </form>
  );
};

export default UserDynamicForm;
