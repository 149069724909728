import React from "react";

const SubscriptionCard = () => {
  const subscriptionPlans = [
    {
      plan: "Monthly Subscription",
      price: 40,
      currency: "US $",
      period: "Month",
      link: process.env.REACT_APP_MONTHLY_SUBSCRIPTION,
    },
    {
      plan: "Yearly Subscription",
      price: 100,
      currency: "US $",
      period: "Year",
      link: process.env.REACT_APP_YEARLY_SUBSCRIPTION,
    },
  ];

  const handleSubscribe = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="max-w-md mx-auto">
      <h2 className="text-2xl font-semibold my-4 text-gray-800 text-center">
        Choose Your Subscription Plan
      </h2>
      {subscriptionPlans.map((plan, index) => (
        <div
          key={index}
          className="bg-white border rounded-lg p-8 shadow-lg mb-6 hover:shadow-xl transition-shadow duration-300"
        >
          <h3 className="text-xl font-medium text-gray-900">{plan.plan}</h3>
          <div className="text-2xl font-extrabold mt-4">
            <span className="text-gray-900">{plan.currency}</span>
            <span className="text-gray-900">{plan.price}/</span>
            <span className="text-lg text-gray-500">{plan.period}</span>
          </div>
          <p className="text-sm text-gray-500 mt-2">
            Get access to more information with our {plan.plan.toLowerCase()}.
          </p>
          <div className="relative mt-6">
            <button
              className="bg-blue-600 text-white py-3 px-5 rounded-full w-full font-semibold"
              onClick={() => handleSubscribe(plan.link)}
            >
              Subscribe Now
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubscriptionCard;
