import React, { useEffect, useState } from "react";
import { postApi } from "../../../../Api/Api";
import Cookies from "js-cookie"
import { toast } from "react-toastify";

const PromoCodeModal = ({
  showModal,
  setShowModal,
  promoCodes,
  setPromoCodes,
}) => {
  const [promoCodelist, setPromoCodeList] = useState([]);

  useEffect(() => {
    setPromoCodeList(promoCodes);
  }, [promoCodes]);

  if (!showModal) return null;

  const handleRemoveCoupon = async (id) => {
    const token = Cookies.get("authToken")
    const updateList = promoCodelist?.filter((item) => item.id !== id);
    setPromoCodeList(updateList);
    const fromData = { id: id };
    const response = await postApi(fromData, "admin/delete-promocode", token);
    if (response?.data) {
        toast.success(response.message)
    }
    setPromoCodes(updateList);
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-fit relative">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 font-bold"
        >
          X
        </button>

        <h2 className="text-xl font-semibold mb-4">All Promo Codes</h2>

        {promoCodelist && promoCodelist.length > 0 ? (
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2 text-left uppercase">Name</th>
                <th className="border border-gray-300 p-2 text-left uppercase">
                  Discount
                </th>
                <th className="border border-gray-300 p-2 text-left uppercase">Code</th>
                <th className="border border-gray-300 p-2 text-left text-nowrap uppercase">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {promoCodelist.map(
                (code, index) =>
                  code?.active && (
                    <tr key={index} className="border-b">
                      <td className="border border-gray-300 p-2">
                        {code.name}
                      </td>
                      <td className="border border-gray-300 p-2 text-black">
                        {code.discount}%
                      </td>
                      <td className="border border-gray-300 p-2">
                        {code.code}
                      </td>
                      <td className="border border-gray-300 p-2 text-center text-red-600">
                        <button onClick={() => handleRemoveCoupon(code.id)}>
                          {" "}
                         Delete
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500 text-center">No promo codes available.</p>
        )}
      </div>
    </div>
  );
};

export default PromoCodeModal;
