import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  operators: [], // Operators is an array
};

const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    addOperators: (state, action) => {
      state.operators = action.payload; // Directly set the array of operators
    },
    clearOperators: (state) => {
      state.operators = []; // Reset to an empty array
    },
  },
});

export const { addOperators, clearOperators } = operatorSlice.actions;
export default operatorSlice.reducer;
